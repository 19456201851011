
import { Client, GetInvitesItem } from "@/types/responses"

const CustomerClientsDialog = () => import("@/components/dialogs/CustomerClientsDialog.vue")
import { DataTableHeader } from "vuetify"
import Vue from "vue"
export default Vue.extend({
	name: "ClientsList",
	components: {
		CustomerClientsDialog
	},
	async mounted() {
		await this.getCustomerClients()
		this.getSelectedClient()
	},
	data(): {
        search: string
		clientDialog: boolean
		selectedItem: null | Record<string, any>
		loading: boolean
		mandatory: boolean
		activeClass: string
		selectedChip: string
        headers: DataTableHeader[]
        timeLeft: number

	} {
		return {
            search: "",
			clientDialog: false,
			selectedItem: null,
			loading: false,
			mandatory: false,
			activeClass: "",
			selectedChip: "",
            headers: [
				{ text: "Client", value: "name", sortable: false },
				{ text: "Client Id", value: "id", sortable: false },
				{ text: "Action", value: "action", sortable: false, filterable: false },
			],
            timeLeft: 2
		}
	},
	computed: {
		customerClientsComplete(): Record<string, any>[] {
			return this.$store.state.context.customerClients;
		},
	},
	methods: {
		async clientClick(item: Record<string, any>): Promise<void> {
			this.selectedItem = item
			await this.setSelectedClient()
            this.showClientDialog(item)
		},
		showClientDialog(item: Record<string, any>): void {
			this.clientDialog = true
		},
		closeClientDialog(removeClient?: boolean | null): void {
			this.clientDialog = false

            console.log("removeclient value: ", removeClient)

            if(removeClient){
                console.log("Refreshing clientslist...")
                this.refreshCustomerClients()

            }
		},
        async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 900)
			})
		},
		async refreshCustomerClients(): Promise<void> {
			this.loading = true
            console.log("Refreshing...")
			try {
                await this.countDownTimer()
				await this.$vStore.dispatch("context/updateCustomerClients")
			} catch (e) {
				console.warn("Could not find any customerClients for this customer", e)
			}
			this.loading = false
		},
		async getCustomerClients(): Promise<void> {
			if (this.loading) return
			if (this.$store.state.context.customerClients.length > 0) return
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updateCustomerClients")
				this.loading = false
			} catch (e) {
				console.warn("Could not find any customerClients for this customer", e)
			}
		},
		async setSelectedClient(): Promise<void> {
			const hit = this.$store.state.context.customerClients.find((client: Client) => client.id === this.selectedItem!.id)
			if(hit) await this.$vStore.dispatch("context/setClient", hit)
		},
		getSelectedClient(): void {
			if(this.$store.state.context.selectedClient){
				this.selectedChip = this.$store.state.context.selectedClient.id;
			}
		},
	},
})
